import React from "react";
import { DateTime } from 'luxon';


const dateTimeCol = {
    Header: "Date & Time",
    accessor: "date",
    headerClassName: "center",
    maxWidth: 50,
    sortType: (a, b) => {
        return DateTime.fromFormat(a.original.date, "LLL d, yyyy") > DateTime.fromFormat(b.original.date, "LLL d, yyyy") ? 1 : -1;
    },
    Cell: ({ row }) => {
        return (
            <div>
                {row.original.date}<br />{row.original.time}
            </div>
        )
    },
};

const visitingTeamCol = {
    Header: "Visiting Team",
    accessor: "visitingTeam",
    headerClassName: "center",
    maxWidth: 50,
};

const atCol = {
    Header: "",
    accessor: "at",
    headerClassName: "center",
    maxWidth: 10,
    Cell: () => <div >@</div>,
};

const homeTeamCol = {
    Header: "Home Team",
    accessor: "homeTeam",
    headerClassName: "center",
    maxWidth: 50,
};

const venueCol = {
    Header: "Venue",
    accessor: "venue",
    headerClassName: "center",
    maxWidth: 65,
    Cell: ({ row }) => {
        return (
            <div >
                <a href={row.original.venueLink} target="_blank" rel="noopener noreferrer" style={{ color: "black" }}>{row.original.venue}</a>
            </div>
        )
    },
};

const locationCol = {
    Header: "Location",
    accessor: "location",
    headerClassName: "center",
    maxWidth: 65,
};

const visitingScoreCol = {
    Header: "",
    accessor: "visitingScore",
    maxWidth: 20,
};

const homeScoreCol = {
    Header: "",
    accessor: "homeScore",
    maxWidth: 20,
};

const scheduleColumns = [dateTimeCol, visitingTeamCol, atCol, homeTeamCol, locationCol, venueCol];
const recentScoreColumns = [dateTimeCol, visitingTeamCol, visitingScoreCol, atCol, homeScoreCol, homeTeamCol, locationCol, venueCol]

export default {
    scheduleColumns,
    recentScoreColumns,
};

import styled from "styled-components";

export const HistoryContainer = styled.div`
  width: 50%;
  margin: 100px auto;

  @media(max-width: 768px) {
    width: 75%;
    margin: 50px auto;
  }

  @media(max-width: 425px) {
    width: 90%;
    margin: 50px auto;
  }

  .caption {
    font-family: 'EB Garamond', serif;
    font-size: 14px;
    color: #757573;
  }

  .center {
    text-align: center;
    margin: 20px auto;
  }

  .content {
    font-family: 'EB Garamond', serif;
    font-size: 22px;
  }

  .img-full img {
    width: 100%;
  }

  .img-float-left {
    float: left;
    margin-right: 25px;
    margin-top: 10px;

    @media(max-width: 768px) {
      float: left;
      margin: 10px 25px 0 0;
      img {
        width: auto;
      }
    }

    @media(max-width: 425px) {
      float: none;
      margin: 0 auto;
      img {
        width: 100%;
      }
    }
  }
`;
import styled, { keyframes } from "styled-components";


const spin = keyframes`
  to { -webkit-transform: rotate(360deg); }
`;

export const Spinner = styled.div`
  display: inline-block;
  margin: 50px auto;
  width: 50px;
  height: 50px;
  border: 3px solid rgba(100,100,100,.3);
  border-radius: 50%;
  border-top-color: #0c2036;
  animation: ${spin} 1s ease-in-out infinite;
  -webkit-animation: ${spin} 1s ease-in-out infinite;
`;

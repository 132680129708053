import React, { useEffect, createRef } from "react";
import { TwitterTimelineEmbed } from 'react-twitter-embed';
import { Link, useLocation } from "react-router-dom";
import { Helmet } from "react-helmet";

import { Contact } from "../../components";
import { HomeContainer, TitleBanner, BannerImageContainer, AboutContainer, NewsContainer, HomeHistoryContainer, Footer } from "./styles";
import teamPic from "../../assets/teamPic.png";
import nyt_lockerroom from "../../assets/nyt_lockerroom.webp";
import stn_team_1905 from "../../assets/stn_team_1905.webp";

const Home = () => {
  const location = useLocation();
  const contactRef = createRef();

  useEffect(() => {
    location && location.pathname === '/contact' && contactRef.current && contactRef.current.scrollIntoView({ behavior: 'smooth' });
  }, [location, contactRef]);

  return (
    <HomeContainer>
      <Helmet>
        <meta charSet="utf-8" />
        <title>New York St. Nicks</title>
      </Helmet>
      <TitleBanner>
        <div>
          <div className="year-est">EST. 1896</div>
          <div className="team-name">New York St. Nick's<br />Hockey Club</div>
        </div>
      </TitleBanner>
      <BannerImageContainer>
        <img className="banner-image" src={teamPic} alt="NY St. Nicks Team" />
      </BannerImageContainer>
      <AboutContainer>
        <div className="about-div">
          <div className="about-title">NEW YORK'S PREMIER ICE HOCKEY CLUB SINCE 1896</div>
          <div className="about-content">The New York St. Nick's hockey team is comprised of former professional and collegiate players living and working in and around New York City. Hailing from coast to coast in both Canada and the United States, the players work predominantly in financial services, law, and technology. Year round the players meet once a week to skate at the Chelsea Piers Sky Rink in New York City. Throughout the season, the team plays upwards of 10 exhibition games against collegiate and other men's senior elite teams around the country.</div>
        </div>
      </AboutContainer>
      <NewsContainer>
        <div className="twitter-container">
          <div className="twitter-header">@StNicksHockey</div>
          <TwitterTimelineEmbed
            sourceType="profile"
            screenName="StNicksHockey"
            options={{ height: 700 }}
          />
        </div>
        <div className="headlines">
          <h3>
            St. Nicks in the NY Times:
            <br />
            "Don't Call St. Nicholas Hockey Club a 'Beer League' team"
          </h3>
          <p className="date">March 22, 2019</p>
          <p className="caption"><i>By David Caldwell (New York Times)</i></p>
          <h5>
            Full Article: <br />
            <a
              href="https://www.nytimes.com/2019/03/22/sports/st-nicholas-hockey-club.html"
              target="_blank"
              rel="noopener noreferrer"
            >
              <u>"Don't Call St. Nicholas Hockey Club a 'Beer League' team"</u>
            </a>
          </h5>
          <img src={nyt_lockerroom} alt="St. Nicks in the lockerroom" />
          <p className="caption">
            Members of the St. Nicholas Hockey Club before a scrimmage at Chelsea Piers in January. St. Nick’s, formed in 1896, was one of the nation’s first amateur hockey clubs.
          </p>
          <p className="caption">Credit: James Keivom for The New York Times</p>
        </div>
      </NewsContainer>
      <HomeHistoryContainer>
        <div className="history-pic">
          <img src={stn_team_1905} alt="St. Nicks, 1905" />
        </div>
        <div className="home-history-content">
          <div className="hhc-title">Over 100 Years of Elite Men's Ice Hockey</div>
          <div className="hhc-desc">
            Organized hockey was played in the Metropolitan Area a lot earlier than most people would imagine and two rinks emerged late in the 19th Century, one in Manhattan and the other in Brooklyn, that rivaled those in Canada.
          </div>
          <div className="button-div">
            <Link to="/history"><button>Read More</button></Link>
          </div>
        </div>
      </HomeHistoryContainer>
      <Contact id="contact" ref={contactRef} />
      <Footer />
    </HomeContainer >
  );
}

export default Home;
import styled, { css } from "styled-components";

const TableContainer = styled.div`
  .table {
    border-spacing: 0;
    border: 1px solid rgba(0, 0, 0, 0.1);
  }

  @media(min-width: 900px) {
    width: 100%;
  }
`;

const HeaderRow = styled.div`  
  background-color: ${(props) => props.isSubTable ? '#ACC3D5' : '#0c2036'};
  color: #ffffff;
  font-size: 14px;
  font-weight: 700;
  text-transform: uppercase;
  user-select: none;
  white-space: normal;
`;

const HeaderColumn = styled.div`
  margin: 0;
  padding: 0.5rem;

  ${(props) =>
        props.column.isSorted &&
        css`
      box-shadow: inset 0 ${props.column.isSortedDesc ? -3 : 3}px 0 0 green;
  `}

  &.center {
    text-align: center;
  }
`;

const BodyRow = styled.div`
  ${(props) =>
        props.index % 2 === 0 &&
        css`
      background: #f8f8f8;
    `}
`;

const SubComponentRow = styled.div`
  td {
    margin-left: 5px;
  }
`;

export { TableContainer, HeaderRow, HeaderColumn, BodyRow, SubComponentRow };

import React, { useRef, useState, forwardRef, useEffect } from 'react';
import emailjs from '@emailjs/browser';

import { ContactFormContainer } from './styles';


const Contact = forwardRef((props, ref) => {
  const form = useRef();
  const [emailSent, setEmailSent] = useState(false);
  const [isValid, setIsValid] = useState(false);
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [email, setEmail] = useState('');
  const [name, setName] = useState('');
  const [subject, setSubject] = useState('');
  const [message, setMessage] = useState('');

  const sendEmail = (e) => {
    e.preventDefault();

    if (!isValid) {
      setShowErrorMessage(true);
      return;
    }

    emailjs.sendForm('service_ze63z2k', 'template_12umqs3', form.current, 'OL_sJEISoNT54f0sj')
      .then((result) => {
        setEmailSent(true);
      }, (error) => {
        console.log(error.text);
      });
  };

  const handleChange = (e) => {
    switch (e.target.name) {
      case "email":
        setEmail(e.target.value);
        break;
      case "name":
        setName(e.target.value);
        break;
      case "subject":
        setSubject(e.target.value);
        break;
      case "message":
        setMessage(e.target.value);
        break;
      default: return;
    }
  }

  useEffect(() => {
    if (email.length > 0 && name.length > 0 && subject.length > 0 && message.length > 0) {
      setIsValid(true);
    } else {
      setIsValid(false);
    }
  }, [email, name, subject, message])

  return (
    <ContactFormContainer ref={ref}>
      <div className="contact-header">
        Contact
      </div>
      <div className="contact-form-container">
        <form id="contact-form" ref={form} onSubmit={sendEmail}>
          <div className="form-group email">
            <input placeholder="Email" type="email" className="form-control" name="email" value={email} onChange={handleChange} />
          </div>
          <div className="form-group name">
            <input placeholder="Name" type="text" className="form-control" name="name" value={name} onChange={handleChange} />
          </div>
          <div className="form-group subject">
            <input placeholder="Subject" type="text" className="form-control" name="subject" value={subject} onChange={handleChange} />
          </div>
          <div className="form-group message">
            <textarea placeholder="Message" className="form-control" name="message" value={message} onChange={handleChange} />
          </div>
          <div className="button-div send">
            <button type="submit" className="btn btn-primary">Send</button>
          </div>
        </form>
        {!isValid && showErrorMessage && <span className="msg error">Form is invalid, please fill out all fields</span>}
        {emailSent && <span className="msg success">Thank you for your message, we will be in touch in no time!</span>}
      </div>
    </ContactFormContainer>
  );
});

export default Contact;

import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import { RosterTable, News, History, Home, Schedule } from "./views";
import { AppContainer, Body } from "./styles";
import ScrollToTop from "./core/ScrollToTop";
import Nav from "./components/navBar/NavBar";


function App() {
    return (
        <BrowserRouter>
            <AppContainer>
                <Nav />
                <Body>
                    <ScrollToTop>
                        <Routes>
                            <Route path="/" element={<Home />} />
                            <Route path="/news" element={<News />} />
                            <Route path="/history" element={<History />} />
                            <Route path="/players" element={<RosterTable />} />
                            <Route path="/contact" element={<Home />} />
                            <Route path="/schedule" element={<Schedule />} />
                        </Routes>
                    </ScrollToTop>
                </Body>
            </AppContainer>
        </BrowserRouter >
    );
}

export default App;

import styled from 'styled-components';

export const NewsContainer = styled.div`
  width: 62.5%;
  margin: 0 auto;
  padding: 80px 0;

  .twitter-container {
    width: 33%;
    float: left;
    margin-right: 25px;
    margin-top: 20px;

    .twitter-header {
      font-family: 'EB Garamond', serif;
      font-size: 20px;
      background: #0c2036;
      color: white;
      text-align: center;
      padding: 20px;
    }
  }
  @media(max-width: 769px) {
    width: 80%;
    margin: 0 auto;
    padding: 40px 0;

    .twitter-container {
      margin-right: 20px;
      margin-bottom: 20px;
    }
  }

  @media(max-width: 500px) {
    width: 90%;
    margin: 0 auto;
    padding: 30px 0;

    .twitter-container {
      width: 80%;
      float: none;
      margin: 0 auto 40px;
    }
    .twitter-header {
      font-size: 16px;
      padding: 20px;
    }
  }
`;

export const StoryContainer = styled.div`
  font-family: 'EB Garamond', serif;
  margin-bottom: 60px;
  color: #0B1B34;

  h1 {
    font-size: 54px;
    text-align: center;
    margin: 0 20px;
  }

  p {
    font-size: 22px;
  }

  img {
    margin: 0 auto;
    display: block;
  }

  .date {
    font-family: 'Poppins', sans-serif;
    font-size: 14px;
    text-transform: uppercase;
    color: #ACC3D5;
    letter-spacing: 2px; 
  }

  .author {
    font-size: 14px;
    color: #757573;
    font-style: italic;
  }

  @media(max-width: 769px) {
    h1 {
      font-size: 40px;
    }

    p {
      font-size: 18px;
    }

    img {
      width: 100%;
    }
  }

  @media(max-width: 500px) {
    h1 {
      font-size: 36px;
    }
  }
`;
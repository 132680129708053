import React from "react";
import { useTable, useExpanded, useSortBy, useFlexLayout } from "react-table";
import { TableContainer, HeaderRow, HeaderColumn, BodyRow } from "./styles";

import {
    faLongArrowAltDown,
    faLongArrowAltUp,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const StnTable = ({
    columns: userColumns,
    data,
    renderRowSubComponent,
    defaultSorted,
    isSubTable = false,
}) => {
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
        visibleColumns,
    } = useTable(
        {
            columns: userColumns,
            data,
            initialState: {
                sortBy: defaultSorted || [],
            },
        },
        useFlexLayout,
        useSortBy,
        useExpanded
    );

    return (
        <TableContainer>
            <div {...getTableProps()} className="table">
                <div>
                    {headerGroups.map((headerGroup) => (
                        <HeaderRow isSubTable={isSubTable} {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map((column) => {
                                return (
                                    <HeaderColumn
                                        column={column}
                                        {...column.getHeaderProps(column.getSortByToggleProps())}
                                        className={column.headerClassName}
                                    >
                                        {column.render("Header")}
                                        <span>
                                            &nbsp;
                                            {column.isSorted ? (
                                                column.isSortedDesc ? (
                                                    <FontAwesomeIcon icon={faLongArrowAltDown} />
                                                ) : (
                                                    <FontAwesomeIcon icon={faLongArrowAltUp} />
                                                )
                                            ) : (
                                                ""
                                            )}
                                        </span>
                                    </HeaderColumn>
                                )
                            })}
                        </HeaderRow>
                    ))}
                </div>

                <div {...getTableBodyProps()}>
                    {rows.map((row, i) => {
                        prepareRow(row);
                        return (
                            <React.Fragment key={i}>
                                <BodyRow index={i} {...row.getRowProps()} className="body-row">
                                    {row.cells.map((cell) => {
                                        return (
                                            <div {...cell.getCellProps()} className="td">{cell.render("Cell")}</div>
                                        );
                                    })}
                                </BodyRow>
                                {row.isExpanded ? (
                                    <div className="tr">
                                        <div colSpan={visibleColumns.length} className="td">
                                            {renderRowSubComponent({ row })}
                                        </div>
                                    </div>
                                ) : null}
                            </React.Fragment>
                        );
                    })}
                </div>
            </div>
        </TableContainer>
    );
};

export default React.memo(StnTable);

import styled from "styled-components";
import { Link } from "react-router-dom";

const NavBarContainer = styled.div`
  width: 100vw;
  height: 120px;
  background: white;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  display: grid;
  grid-template-columns: 1fr 120px 1fr;
  align-items: center;
  box-shadow: 0 0 25px 0 black;
  z-index: 3;

  div {
    margin: 0 auto;
  }

  @media(max-width: 768px) {
    height: 80px;
  }
`;

const Logo = styled.img`
  height: 116px;
  @media(max-width: 768px) {
    height: 80px;
  }
`;

const LogoLink = styled(Link)`
  text-align: center;
`;

const StyledLink = styled(Link)`
  @media(max-width: 768px) {
    display: none;
  }
  font-family: 'Poppins', sans-serif;
  font-size: 14px;
  color: #0c2036;  
  margin: 15px;
  text-decoration: none;
  transition: 0.2s;

  :hover {
    color: lightblue;
    text-decoration: none;
    text-shadow: 0 0 1px #ffffff;
  }
`;

export const HamburgerDiv = styled.div`
  transform: translateX(20px);
  width: 100%;
`;

export const HamburgerMenu = styled.div`
  @media(min-width: 769px) {
    display: none;
  }
  display: flex;
  align-items: center;
  width: 40px;
  height: 40px;
  cursor: pointer;
  transition: all .2s ease-in-out;
  margin: 0 40px 0 auto !important;
  
  div:first-child {
    width: 25px;
    height: 3px;
    background: #0c2036;
    border-radius: 5px;
    box-shadow: 0 2px 5px rgba(255, 101, 47, .2);
    transition: all .2s ease-in-out;
  }

  div:first-child::before,
    div:first-child::after {
    content: '';
    position: absolute;
    width: 25px;
    height: 3px;
    background: #0c2036;
    border-radius: 5px;
    box-shadow: 0 2px 5px rgba(255, 101, 47, .2);
    transition: all .2s ease-in-out;
  }

  div:first-child::before {
    transform: translateY(-8px);
  }

  div:first-child::after {
    transform: translateY(8px);
  }

  ${props => props.menuopen === 1 && `
    div:first-child {
      transform: translateX(-25px);
      background: transparent;
      box-shadow: none;

      ::before {
        transform: rotate(45deg) translate(17.5px, -17.5px);
      }        
      ::after {
        transform: rotate(-45deg) translate(17.5px, 17.5px);
      }
    }
  `}
`;

export const MobileLinks = styled.div`
  ${props => props.menuopen === 1 && `
    display: grid;

    position: absolute;
    right: 0;
    top: 80px;
    bottom: 0px;
    width: inherit;
    z-index: 12;
    opacity: 0.98;

    div {
      background-color: #0c2036;
      height: 100vh;
      width: 100%;
    }
  `}

  ${props => props.menuopen === 0 && `
    display: none;
  `}
`;

export const MobileNavItem = styled(Link)`
  background-color: #0c2036;
  color: white;
  text-align: center;
  padding: 10px;
  height: 45px;
  text-decoration: none;
  font-size: 36px;
  font-family: 'EB Garamond', serif;
  letter-spacing: 0.1em;

  &:hover {
    color: lightblue;
    text-shadow: 2px 4px 4px rgba(0, 0, 0, 0.75);
  }
`;

const Icons = styled.div`
  position: fixed;
  right: 20px;

  * {
    padding: 5px;
  }
`;

export { NavBarContainer, StyledLink, Logo, Icons, LogoLink };

import React, { useEffect, useState, useCallback } from "react";
import { API } from "aws-amplify";
import { Helmet } from "react-helmet";

import StnTable from "../../core/StnTable/StnTable";
import { RosterContainer, RosterTableContainer, SubStnTable, SubComponentContainer } from "./styles";
import columnDefs from "./columnDefs.js";
import LoadingSpinner from "../../components/loadingSpinner/LoadingSpinner";

API.configure({
    API: {
        endpoints: [
            {
                name: "stn_app",
                endpoint:
                    "https://cpba6di3s4.execute-api.us-east-1.amazonaws.com/default",
            },
        ],
    },
});

const RosterTable = () => {
    const [rosterData, setRosterData] = useState(null);

    useEffect(() => {
        API.get("stn_app", "/players")
            .then((response) => {
                setRosterData(JSON.parse(response.body));
            })
            .catch((err) => {
                console.log(err);
            });
    }, []);

    const activeRoster = rosterData && rosterData.filter(player => player.active === 1);

    const renderRowSubComponent = useCallback(
        ({ row }) => {
            return row.original.position === "G" ?
                (
                    <SubComponentContainer>
                        {row.original.stats ? <SubStnTable
                            isSubTable={true}
                            columns={columnDefs.goalieCols}
                            data={row.original.stats}
                        /> : <div>Statistics N/A</div>}
                    </SubComponentContainer>
                )
                : (
                    <SubComponentContainer>
                        {row.original.stats ? <SubStnTable
                            isSubTable={true}
                            columns={columnDefs.playerCols}
                            data={row.original.stats}
                        /> : <div>Statistics N/A</div>}
                    </SubComponentContainer>
                )
        },
        []
    );

    return (
        <RosterContainer>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Players | New York St. Nicks</title>
            </Helmet>
            <div className="roster-title">2023-24 St. Nicks Roster</div>
            <RosterTableContainer>
                {!rosterData && <LoadingSpinner />}
                {rosterData && (
                    <StnTable
                        columns={columnDefs.columns}
                        data={activeRoster}
                        renderRowSubComponent={renderRowSubComponent}
                        defaultSorted={[{ id: "name" }]}
                    />
                )}
            </RosterTableContainer>
        </RosterContainer>
    );
};

export default RosterTable;

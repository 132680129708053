import React from "react";
import { faCaretRight, faCaretDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const SubComponentIcon = (row) => {
    return (
        <FontAwesomeIcon
            icon={row.isExpanded ? faCaretDown : faCaretRight}
            color={"green"}
            style={{ width: "1.3em", height: "1.3em", cursor: "pointer" }}
            onClick={() => {
                row.toggleRowExpanded();
            }}
        />
    );
};

export default SubComponentIcon;

import styled from "styled-components";

export const HomeContainer = styled.div`
  margin: 0;
  width: 100%;

  .banner-image {
    width: 100%;
  }
`;

export const TitleBanner = styled.div`
  background-color: #0c2036;
  height: 440px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;

  .year-est {
    font-family: 'Poppins', sans-serif;;
    font-size: 14px;
    color: #ACC3D5;
  }

  .team-name {
    font-family: 'EB Garamond', serif;
    font-size: 66px;
    color: white;
  }

  @media(max-width: 425px) {
    height: 220px;

    .team-name {
      margin-top: 10px;
      font-size: 34px;
    }
  }

  @media(max-width: 768px) {
    height: 330px;

    .team-name {
      margin-top: 10px;
      font-size: 42px;
    }
  }
`;

export const BannerImageContainer = styled.div`
  display: flex;
`;

export const AboutContainer = styled.div`
  background-color: #013821;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;

  .about-div {
    max-width: 750px;
    padding: 50px 0;

    .about-title {
      font-family: 'Poppins', sans-serif;;
      font-size: 14px;
      color: #ACC3D5;
      margin-bottom: 30px;
    }

    .about-content {
      font-family: 'EB Garamond', serif;
      font-size: 24px;
      line-height: 40px;
      color: white;
      margin-bottom: 50px;
    }
  }

  @media(max-width: 769px) {
    .about-div {
      max-width: 85%;
      
      .about-title {
        font-size: 20px;
        margin: 40px auto;
      }

      .about-content {
        font-size: 18px;
        line-height: 24px;
        text-align: left;
        margin: 0 auto 50px
      }
    }
  }

  @media(max-width: 425px) {
    .about-div {
      max-width: 90%;
      
      .about-title {
        font-size: 16px;
      }

      .about-content {
        font-size: 18px;
        line-height: 24px;
      }
    }
  }
`;

export const NewsContainer = styled.div`
  width: 75%;
  margin: 40px auto;
  display: grid;
  grid-template-columns: 1fr 2fr;

  .twitter-container {
    .twitter-header {
      font-family: 'EB Garamond', serif;
      font-size: 20px;
      background: #0c2036;
      color: white;
      text-align: center;
      padding: 20px;
    }
  }

  .headlines {
    margin-left: 60px;

    h3 {
      font-family: 'EB Garamond', serif;
      font-size: 34px;
      color: #0c2036;
      text-align: center;
    }

    .date {
      font-family: 'Poppins', sans-serif;;
      font-size: 14px;
      color: #ACC3D5;
      letter-spacing: 2px;
    }

    a {
      color: #013821;
    }

    .caption {
      font-family: 'EB Garamond', serif;
      font-size: 14px;
      color: #757573;
    }

    h5 {
      font-family: 'EB Garamond', serif;
      font-size: 24px;
      color: #0c2036;
    }

    img {
      width: 100%;
    }
  }

  @media(max-width: 600px) {
    width: 90%;
    margin: 40px auto;
    display: block;

    .twitter-container {
      width: 80%;
      margin: 0 auto;

      .twitter-header {
        font-size: 20px;
        padding: 20px;
      }
    }

    .headlines {
      margin: 0 auto;


      h3 {
        font-size: 28px;
      }

      .date {
        font-size: 12px;
        letter-spacing: 2px;
      }

      a {
        color: #013821;
        font-size: 22px;
      }

      .caption {
        font-size: 14px;
      }

      h5 {
        margin: 20px auto;
        font-size: 24px;
      }

      img {
        width: 100%;
      }
    }
  }
`;

export const HomeHistoryContainer = styled.div`
  background-color: #E8E6E6;
  display: grid;
  grid-template-columns: 2fr 2fr;
  font-family: 'EB Garamond', serif;
  padding: 100px ;

  .history-pic {
    margin: 20px;
  }

  .home-history-content {
    color: #0B1b34;
    margin: 0 0 0 20px;

    .hhc-title {
      font-size: 51px;
    }

    .hhc-desc {
      font-size: 20px;
      margin: 40px 0;
    }

    .button-div {
      display: flex;
    }

    a {
      margin: 0 auto;
    }

    button {
      font-size: 16px;
      background-color: #0B1b34;
      color: white;
      height: 40px;
      padding: 0 40px;
      align-content: center;
    }

    button:hover {
      cursor: pointer;
    }
  }
  @media(max-width: 1000px) {
    display: block;
    width: 100%;
    padding: 0;
    padding-top: 10px;

    .history-pic {
      margin: 20px auto 0;
      width: 90%;
      img {
        width: 100%;
        margin: 20px auto;
      }
    }

    .home-history-content {
      width: 90%;
      margin: 0 auto;
      padding-bottom: 20px;

      .hhc-title {
        font-size: 34px;
        text-align: center;
      }

      .hhc-desc {
        font-size: 18px;
        margin: 30px 0;
      }
    }
  }
`;

export const Footer = styled.div`
  background-color: white;
  height: 75px;
`;
import React from "react";
import { DateTime } from "luxon";

import { faLinkedin } from "@fortawesome/free-brands-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import SubComponentIcon from "../../core/SubComponentIcon";

import "semantic-ui-flag/flag.min.css";

const columns = [
    {
        id: "SubComponent",
        Header: "",
        width: 35,
        maxWidth: 35,
        Cell: ({ row }) => <div style={{ textAlign: "center" }}>{SubComponentIcon(row)}</div>,
    },
    {
        Header: "Name",
        id: "name",
        accessor: "lastName",
        Cell: ({ row }) => {
            let firstName = row.original.firstName.charAt(0).toUpperCase() + row.original.firstName.slice(1);
            let lastName = row.original.lastName.charAt(0).toUpperCase() + row.original.lastName.slice(1);
            return (
                row.original.linkedinUrl ?
                    <a href={row.original.linkedinUrl} target="_blank" rel="noopener noreferrer" style={{ color: "black" }}>
                        {firstName + ' ' + lastName}
                    </a>
                    : firstName + ' ' + lastName
            )
        },
        minWidth: 120,
    },
    {
        Header: "Pos",
        accessor: "position",
        headerClassName: "center",
        Cell: ({ row }) => {
            let pos = row.original.position;
            if (pos.includes("D") || pos.includes("G")) {
                return <div style={{ textAlign: "center" }}>{pos}</div>;
            } else {
                return <div style={{ textAlign: "center" }}>F</div>;
            }
        },
        sortType: ((a, b, id) => {
            if (a.values[id] === "D") return 1;
            if (b.values[id] === "G") return 2;
            return -1;
        }),
        maxWidth: 60,
    },
    {
        Header: "L/R",
        accessor: "shoots",
        headerClassName: "center",
        Cell: row => <div style={{ textAlign: "center" }}>{row.value}</div>,
        maxWidth: 60,
    },
    {
        Header: "Ht",
        accessor: "height",
        headerClassName: "center",
        Cell: row => <div style={{ textAlign: "center" }}>{row.value}</div>,
        maxWidth: 60,
    },
    {
        Header: "Wt",
        accessor: "weight",
        headerClassName: "center",
        Cell: row => <div style={{ textAlign: "center" }}>{row.value}</div>,
        maxWidth: 80,
    },
    {
        Header: "College",
        accessor: "college",
        Cell: ({ row }) => {
            if (!row.original.college) return '--'
            return `${row.original.college} '${row.original.collegeYear.slice(5)}`;
        },
        minWidth: 160,
    },
    {
        Header: "Age",
        accessor: "dob",
        headerClassName: "center",
        Cell: ({ row }) => {
            if (!row.original.dob) return <div style={{ textAlign: "center" }}>--</div>;
            const bday = DateTime.fromFormat(row.original.dob, "LLL d, yyyy");
            const today = DateTime.local();
            const diffInYears = Math.floor(today.diff(bday, "years").values.years) || 'N/A';
            return <div style={{ textAlign: "center" }}>{parseInt(diffInYears)}</div>;
        },
        sortType: ((a, b, id) => {
            const timeA = DateTime.fromFormat(a.values[id], "LLL d, yyyy").ts
            const timeB = DateTime.fromFormat(b.values[id], "LLL d, yyyy").ts
            if (timeA > timeB) return 1;
            if (timeB > timeA) return -1;
            return 0;
        }),
        maxWidth: 60,
    },
    {
        Header: "Nation",
        accessor: "nation",
        headerClassName: "center",
        Cell: ({ row }) => (
            <div style={{ textAlign: "center", zIndex: "-1" }}><i className={row.original.nation.slice(0, 2).toLowerCase() + " flag"} /></div>
        ),
        maxWidth: 80,
    },
    {
        Header: "Birthplace",
        accessor: "birthPlace",
        Cell: ({ row }) => {
            let birthPlace = row.original.birthPlace;
            if (birthPlace.includes(', USA') || birthPlace.includes(', CAN')) {
                birthPlace = birthPlace.slice(0, -5);
            }
            return birthPlace;
        }
    },
    {
        Header: "",
        accessor: "linkedinUrl",
        width: 35,
        Cell: ({ row }) => {
            return (
                <div style={{ textAlign: "center" }}>
                    {row.original.linkedinUrl ? <a href={`${row.original.linkedinUrl}`} target="_blank" rel="noopener noreferrer">
                        <FontAwesomeIcon
                            icon={faLinkedin}
                            color={"#0072b1"}
                            style={{ width: "1.3em", height: "1.3em" }}
                        />
                    </a> :
                        <FontAwesomeIcon
                            icon={faLinkedin}
                            color={"gray"}
                            style={{ width: "1.3em", height: "1.3em" }}
                        />
                    }
                </div>
            )
        }
    },
];

const statsTableBaseColumns = [
    {
        Header: "Year",
        accessor: "year",
        maxWidth: 75,
    },
    {
        Header: "Team",
        accessor: "team",
        minWidth: 225,
    },
    {
        Header: "League",
        accessor: "league",
        maxWidth: 100,
    },
    {
        Header: "GP",
        accessor: "gp",
        headerClassName: "center",
        maxWidth: 50,
        Cell: row => <div style={{ textAlign: "center" }}>{row.value}</div>
    },
];

const playerStats = [
    {
        Header: "G",
        accessor: "g",
        headerClassName: "center",
        maxWidth: 50,
        Cell: row => <div style={{ textAlign: "center" }}>{row.value}</div>
    },
    {
        Header: "A",
        accessor: "a",
        headerClassName: "center",
        maxWidth: 50,
        Cell: row => <div style={{ textAlign: "center" }}>{row.value}</div>
    },
    {
        Header: "P",
        accessor: "p",
        headerClassName: "center",
        maxWidth: 50,
        Cell: row => <div style={{ textAlign: "center" }}>{row.value}</div>
    },
    {
        Header: "+/-",
        accessor: "pm",
        headerClassName: "center",
        maxWidth: 50,
        Cell: row => <div style={{ textAlign: "center" }}>{row.value}</div>
    },
    {
        Header: "PIM",
        accessor: "pim",
        headerClassName: "center",
        maxWidth: 50,
        Cell: row => <div style={{ textAlign: "center" }}>{row.value}</div>
    },
];

const goalieStats = [
    {
        Header: "GAA",
        accessor: "gaa",
        headerClassName: "center",
        maxWidth: 50,
        Cell: row => <div style={{ textAlign: "center" }}>{row.value}</div>
    },
    {
        Header: "S%",
        accessor: "svp",
        headerClassName: "center",
        maxWidth: 50,
        Cell: row => <div style={{ textAlign: "center" }}>{row.value}</div>
    },
    {
        Header: "SO",
        accessor: "so",
        headerClassName: "center",
        maxWidth: 50,
        Cell: row => <div style={{ textAlign: "center" }}>{row.value || '-'}</div>
    },
    {
        Header: "WLT",
        accessor: "wlt",
        headerClassName: "center",
        maxWidth: 50,
        Cell: row => <div style={{ textAlign: "center" }}>{row.value || '-'}</div>
    },
];

const playerCols = [...statsTableBaseColumns, ...playerStats];
const goalieCols = [...statsTableBaseColumns, ...goalieStats];

export default {
    columns,
    playerCols,
    goalieCols,
};

import styled from "styled-components";

const AppContainer = styled.div`
  font-family: "Sintony", sans-serif;
  margin: 0;
`;

const Body = styled.div`
  margin-top: 120px;
  
  @media(max-width: 768px) {
    margin-top: 80px;
  }
`;

export { AppContainer, Body };

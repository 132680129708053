import React from "react";
import { Helmet } from "react-helmet";
import { TwitterTimelineEmbed } from 'react-twitter-embed';

import { NewsContainer, StoryContainer } from './styles';

import stn_vs_svalley from '../../assets/stn_vs_svalley.webp';
import stn_vs_moose from '../../assets/stn_vs_moose.webp';

const News = () => {
    return (
        <NewsContainer>
            <Helmet>
                <meta charSet="utf-8" />
                <title>News | New York St. Nicks</title>
            </Helmet>
            <div className="twitter-container">
                <div className="twitter-header">@StNicksHockey</div>
                <TwitterTimelineEmbed
                    sourceType="profile"
                    screenName="StNicksHockey"
                    options={{ height: 600 }}
                />
            </div>
            <StoryContainer>
                <h1>St. Nick's Start Season With 7-4 Win Over Wissahickon</h1>
                <p className="date">December 3rd, 2022</p>
                <p className="author">By Forward Jordan Jancze (Wesleyan '18)</p>
                <p>It was not the one-sided affair that many had predicted, but the New York St. Nicks Hockey Team defeated the visiting Wissahickon Skating Club Saturday night in comfortable fashion 7-4.</p>
                <p>An early Nicks lead put the Philadelphia based Wiss on the ropes thanks to Blair Yaworski (Yale ’08), who ironically was not on the initial roster - but manually added a row to the sign-up spreadsheet prior to puck drop. His attendance was quickly justified after ripping a shot past the visiting goaltender’s blocker in the third minute, and then finding the back of the net again a mere five minutes later after a sustained forecheck by linemates Kitto (Williams ’15) and Corcoran (Brown ’18).</p>
                <p>Minnesota born goaltender Tommy Burke (Bowling Green ’16) had to answer the bell late in the first period during an expected lapse in the Nicks defensive game. A much needed save and a quick breakout pass launched speedy forward Andrew Ammon (Princeton ’14) into the neutral zone after some handy defensive work from Cohen (Yale ’07). Ammon broke for open space though the neutral zone and proceeded to look-off linemates Jancze (Wesleyan ’18) and Goodwin (St. Louis ’12) for a hard shot low blocker. The shot found the back of the net. 3-0 at the end of the first.</p>
                <p>The second period saw a balanced game emerge with Wissahickon finding the back of the net twice. The New York coaches arrived just in time to witness an Ovechkin-like between the legs deke around a Nicks’ defenseman which resulted in a tight angle shot finding the back of the net.</p>
                <p>It was clear early, however, the coaching staff would prove vital. A verbal tirade of the opposition during a defensive zone draw distracted their defensive pair, resulting in yet another Yaworski goal to complete his hat trick. Blue cans were lifted towards the rafters in appreciation of the effort. The Nicks managed another goal late in the second after a sustained forecheck from Goodwin. An (un) intentional change-up thrown at the unexpecting goaltender gave Jancze a tap-in on the backdoor.</p>
                <p>The third period required more crucial goaltending from Burke, who was visibly distraught after seeing the period would be longer than the standard twenty minutes, but the goals kept coming for the Nicks. Jimmy Morrisey (Moose Jr, Franklin Pierce ‘20) put home a rebound for his first of the year after a heavy forecheck from Solin (Harvard ’21) and Minello (Sacred Heart ’17).</p>
                <p>The third also brought with it the kind of physicality you would expect from the Broad Street based squad. In an attempt to keep Yaworski off the board for his fourth tally, a Wiss forward came high on the Yale grad, to which Yaworski took no exception. Brief fisticuffs ensued, resulting in a Wiss powerplay with the bigger man clearly not getting the benefit of the doubt from the officiating crew. Wiss would score two quick goals following the “fight” but were held scoreless for the remainder of the game.</p>
                <p>Snake-bitten for most of the night, Corcoran sealed the deal for the Nick’s late with an empty net goal. Wiss continued to press until the final buzzer, but the Nicks’ defensemen proved too much for the Vets.</p>
                <p>Defenseman David Jarrett (Williams ’15) was impressed with the team effort, but commended the defense for giving the Nick’s forwards the opportunity to get up ice. “It all starts on the back end, and it was our skating that proved the difference,” the Edina, MN native said. “When you have guys like Cohen, Keats (Wesleyan ’11), Gottlieb (Brown ’19), Scherer (Canisius ’20), Mcquire (Colgate ’19) – there’s a reason our forwards could cheat a bit out there.”</p>
                <p>The Nicks continue their preparations for the highly anticipated Jackson Hole trip this Saturday in an inter-squad battle at 4pm EST at Chelsea Piers followed by the annual Holiday party at The Canuck. Happy Holidays and hope to see you there.</p>
            </StoryContainer>
            <StoryContainer>
                <h1>St. Nick's Split with Moose in Jackson</h1>
                <p className="date">January 20th, 2020</p>
                <p className="author">By Defenseman Kevin Peel (Yale '12) &amp; Mariel Wilson (Jackson Hole News &amp; Guide)</p>
                <br />
                <p className="author">Kevin Peel (Yale '12)</p>
                <p>Game 1 between St. Nicks and the Moose was a battle right from the drop of the puck.</p>
                <p>STN jumped out to an early lead when David Jarrett (Williams '15) potted his first career goal on a textbook drive to the far post. Jumping up into the play like a young Sergei Zubov, Jarrett was able to handle a juicy rebound off a shot from Matt Hatch (Union '14).</p>
                <p>New York was able to add to their lead off of a ballistic missile from self-appointed captain, Patrick Raley (SLU '14). Dan Weiniger (Bowdoin '13) found Kevin Peel (Yale '12) circling in no-man's land, who was able to hit Raley with a beautiful feed.</p>
                <p>Moving into the 2nd period, STN seemed to be suffering from a lethal dose of altitude fatigue and over-confidence. A sluggish powerplay, which went 1-12 on the weekend, let up a shorthanded goal which seemed to breathe some life into the hometown Moose. It took a tying goal from the Moose halfway through the 2nd period to wake the Nicks up from their slumber.</p>
                <p>Surprising everyone in the building, particularly his teammates, Todd Keats (Wesleyan '11) took advantage of a neutral zone turnover and flew in on the attack. Faking wide and cutting into the middle with complete disregard for his life, Keats was able to sneeze a shot just inside the far post to give the lead back to STN. Kevin Kaiser (Princeton '10) unsuccessfully attempted to secure a phantom assist on the play.</p>
                <p>STN was able to add some insurance to their lead off a great effort from Brian Ward. Playing like a man possessed in front of his girlfriend's family, Ward managed to backhand toe-drag himself into enemy territory, allowing Matt Killian (Yale '15) to clean up his garbage. Important to note that Killian had just cleared concussion protocol after getting steamrolled three times earlier in the period.  New York skated away with a 4-2 victory. </p>
                <p className="author">Mariel Wilson (Jackson Hole News & Guide)</p>
                <p>The Jackson Hole Moose pulled off a shocking third-period comeback Saturday night against the New York St. Nicks one night after a 4-2 loss in the first game of the series. Fans at Snow King Sports and Events Center cheered as the Moose scored three consecutive goals in just over three minutes, ultimately beating New York 5-2 to stave off a four-game losing streak.</p>
                <p>The St. Nicks came into the third period hot, with another goal just over a minute in, giving them a one score lead. For nearly half of the period it seemed the St. Nicks may had sealed their fate against the Moose, still dominating in shots on goal. But a Moose goal by Brad Improta just after the 12-minute mark brought the game to a contentious third-period tie. Within a minute, a goal by Tom Hartnett gave the Moose the lead. Just over one minute later, a goal by A.J. Sanders brought the Moose to a miraculous two-goal lead.</p>
                <p>​Much of the game came down to the talent of the Moose defense. Although the St. Nicks may have kept their lead in shots on goal throughout the game, goalie Nick Krauss kept a 96% save average. The Moose went on to defeat the New York St. Nicks 5-2.</p>
            </StoryContainer>
            <StoryContainer>
                <h1>St. Nick's Start Season Strong at Home</h1>
                <p className="date">December 15, 2018</p>
                <p className="author">By Defenseman Kevin Peel (Yale '12)</p>
                <p>This past Saturday, the men of St. Nicks took on Wissahickon in the first of two exhibition games set for the 2018-2019 season.</p>
                <p>St. Nicks spent the first 10 minutes shaking off some rust, and a sloppy neutral zone turnover resulted in Wissahickon taking a 1-0 lead. Despite numerous Grade A scoring chances, the Nicks found themselves down 1-0 with 5 minutes left in the first period. That would all change when Carson Cooper (Yale ’16; ECAC Defensive Player of the Year) jammed home a rebound to knot things up at 1 a piece. From there, the floodgates opened and STN quickly scored 3 more. Goals from Matt Cook, Matt Hatch and Jared Keller had the Nicks up 4-1 heading into the 2nd period.</p>
                <p>The goals kept coming in the 2nd, with offensive juggernaut Patrick Raley leading the charge from the back end. Jared Keller, Eric Robinson and Tucker Dayton also found the back of the net, giving the Nicks a comfortable 10-1 lead. STN goaltender, Sean Dougherty, bounced back from the early goal against to make 25 saves, setting the stage for a strong performance from the boys in blue.</p>
                <p>With the Nicks firmly in control of the game, emotions began to run a little high. The lead secured, physicality starting to pick up, and STN started leaning on their grittier Canadian players, namely Carson Cooper, Kevin Peel and Jordan Kary. Despite failing to find the score sheet, Jordan Kary won numerous puck battles and was able to be effective by getting pucks in deep.</p>
                <p>Recent father and self-proclaimed captain, Matt Cook, added 3 secondary assists in the win.</p>
                <p>The win has St. Nicks at 1-0 this season, and will be looking to ride this momentum into their 2 game series against Vail in the new year.</p>
            </StoryContainer>
            <StoryContainer>
                <h1>St. Nick's Beat Suns<br />6-4, 6-3</h1>
                <p className="date">February 17, 2018</p>
                <p className="author">By Defenseman Kevin Peel (Yale '12)</p>
                <p>After getting swept by the Suns in 2017, the STN squad headed west with a new slogan for the President’s Day Weekend series: Revenge Tour 2018. With some fresh blood and young lungs added to the line-up, the Nicks were able to battle the altitude and complete the sweep, with scores of 6-4 and 6-3.</p>
                <img src={stn_vs_svalley} alt="St. Nicks vs. Sun Valley Suns" />
                <p>Friday night started in a fury, with the Nicks scoring twice in the first 39 seconds, en route to a 5-1 lead after the 1st period. The deep bench of the Suns battled back and made it interesting throughout the next two periods, however it wasn’t enough, as the Nicks were able to pull it off with a 6-4 final.  Eric Robinson led the way with 2G and 3A, getting some help from Jesse Root (2G 1A), Trent Ruffalo (3A) and Kevin Peel (3A). Connor Wilson was a stud between the pipes, making 12 of his 28 saves in the 3rd period.</p>
                <p>Saturday night was a different story as the Suns came out hard, refusing to be caught off guard for the second night in a row. After a scoreless 1st period, the Suns were able to jump ahead 2-1 early in the second period, with Kevin Sullivan and Brendan Reich scoring to end the period with the Nicks up 3-2. STN was able to expand their lead, taking advantage of 3 straight powerplays, ending in a 6-3 final. Kevin Sullivan shocked the world by scoring a goal and adding 2 assists, while Alex Ward solidified his Sun Valley Norris campaign with 1G 2A. Connor “the Kitty” Wilson came up big once again, making 25 saves for the Nicks.</p>
            </StoryContainer>
            <StoryContainer>
                <h1>St. Nick's Sweep The Moose<br />4-3, 6-3</h1>
                <p className="date">January 17, 2018</p>
                <p className="author">By Kevin Kaiser (Princeton '10)</p>
                <p>An elite St. Nick’s squad headed to Wyoming this January for two games versus one of the best semi-professional teams in the Rockies, the Jackson Hole Moose.  The Nick’s were focused and hungry, and it showed on the ice and the scoreboard as the boys came away with a sweep in front of raucous crowds at the sold-out Snow King Sports & Events Center.</p>
                <p>STN prevailed in a nail-biter in night one.  The Nick’s went up 4-1 with Dan Weiniger, former Bowdoin College sniper, scoring two beautiful goals off the rush.  But the Moose battled back, scoring two late in the 3rd to make it 4-3, and then went on a 6-on-3 powerplay with one minute remaining.  Some gutsy shot blocks from Matt Raley (St. Lawrence) and key saves from goaltender Andrew Margolin (Conn College) kept the puck out of the net in the final seconds, and the St. Nick’s hung on for the 4-3 win.</p>
                <img src={stn_vs_moose} alt="St. Nicks vs. Jackson Hole Moose" />
                <p>It was all STN on night two – all the boys were buzzing even after a long night of drinking and day of skiing.  Doug Rogers (Harvard), Jordan Kary (Cornell), and Nick Anderson (Williams College) all got in on the action, and the Nick’s cruised to a lop-sided 6-3 victory.</p>
                <p>As always, the team had a great time off the ice enjoying the best that Jackson Hole has to offer: skiing, fishing, and drinking.</p>
            </StoryContainer>
        </NewsContainer>
    );
}

export default News;

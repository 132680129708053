import React, { useState } from "react";
// import { Link } from "react-router-dom";
// import { faCog, faUser, faSignOutAlt } from "@fortawesome/free-solid-svg-icons";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { NavBarContainer, StyledLink, Logo, HamburgerMenu, LogoLink, HamburgerDiv, MobileLinks, MobileNavItem } from "./styles";
import logo from "../../assets/logo.png";

const NavBar = () => {
    const [navmenuopen, setNavMenuOpen] = useState(0);

    const clickHandler = () => setNavMenuOpen(0);

    return (
        <NavBarContainer>
            <div>
                <StyledLink to="/news">News</StyledLink>
                <StyledLink to="/history">History</StyledLink>
                <StyledLink to="/players">Players</StyledLink>
                <StyledLink to="/schedule">Schedule</StyledLink>
                <StyledLink to="/contact">Contact</StyledLink>
            </div>
            <LogoLink to="/"><Logo src={logo} onClick={clickHandler} /></LogoLink>
            <HamburgerDiv>
                <HamburgerMenu menuopen={navmenuopen} onClick={() => setNavMenuOpen(navmenuopen === 1 ? 0 : 1)}>
                    <div></div>
                </HamburgerMenu>
            </HamburgerDiv>
            <MobileLinks menuopen={navmenuopen}>
                <MobileNavItem to={"/"} menuopen={navmenuopen} onClick={clickHandler}>Home</MobileNavItem>
                <MobileNavItem to={"/news"} menuopen={navmenuopen} onClick={clickHandler}>News</MobileNavItem>
                <MobileNavItem to={"/history"} menuopen={navmenuopen} onClick={clickHandler}>History</MobileNavItem>
                <MobileNavItem to={"/players"} menuopen={navmenuopen} onClick={clickHandler}>Players</MobileNavItem>
                <MobileNavItem to={"/schedule"} menuopen={navmenuopen} onClick={clickHandler}>Schedule</MobileNavItem>
                <MobileNavItem to={"/contact"} menuopen={navmenuopen} onClick={clickHandler}>Contact</MobileNavItem>
                <div onClick={clickHandler}></div>
            </MobileLinks>
            {/* <Icons>
        <Link to="admin">
          <FontAwesomeIcon
            icon={faCog}
            color={"#ffffff"}
            style={{ width: "1.3em", height: "1.3em" }}
          />
        </Link>
        <Link to="profile">
          <FontAwesomeIcon
            icon={faUser}
            color={"#ffffff"}
            style={{ width: "1.3em", height: "1.3em" }}
          />
        </Link>
        <FontAwesomeIcon
          icon={faSignOutAlt}
          color={"#ffffff"}
          style={{ width: "1.3em", height: "1.3em" }}
        />
      </Icons> */}
        </NavBarContainer>
    );
};

export default NavBar;

import styled from "styled-components";
import StnTable from "../../core/StnTable/StnTable";

export const ScheduleContainer = styled.div`
    font-family: 'EB Garamond', serif;
    padding: 50px;

    .table-cont {
        min-width: 600px;
        font-size: 18px;
        overflow-x: scroll;

        .td {
            padding: 8px 0;
            text-align: center;
            align-items: center;
        }
    }

    @media(max-width: 425px) {
      padding: 15px 8px;
      font-size: 13px;
    }
`;

export const ScheduleTitle = styled.h1`
    font-size: 48px;
    margin-top: 0;

    @media(max-width: 425px) {
        font-size: 34px;
    }
`;

export const ScheduleTable = styled(StnTable)`
    
`;
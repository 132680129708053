import styled from 'styled-components';

export const ContactFormContainer = styled.div`
  background: #ACC3D5;
  color: white;
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding: 100px 60px;
  font-family: 'EB Garamond', serif;
  
  .contact-header {
    text-align: center;
    font-size: 52px;
  }

  @media(max-width: 769px) {
    display: inherit;
    padding: 50px 20px;
    .contact-header {
      font-size: 36px;
      margin-bottom: 10px;
    }
  }

  .email {
    grid-area: email;
  }
  .name {
    grid-area: fullname;
  }
  .subject {
    grid-area: subject;
  }
  .message {
    grid-area: message;
  }
  .send {
    grid-area: send;
  }

  .contact-form-container {
    form {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-template-rows: 1fr 1fr 3fr 1fr;
      grid-template-areas:
        "email fullname"
        "subject subject"
        "message message"
        "send send";
    }

    input, textarea {
      width: 100%;
      margin: 5px 5px 0;
      color: white;
      padding-top: 10px;
      border: 0;
      z-index: 1;
      background-color: transparent;
      font: inherit;
      font-size: 18px;

      ::placeholder { 
        color: white;
        opacity: .95;
      }

      &:focus {
        color: white;
        outline: 0;
        border-bottom-color: #665856;
      }

      &:invalid {
        color: red;
        border-bottom-color: red;
      }
    }

    textarea {
      height: 80%;
    }

    .button-div {
      display: flex;
    }

    button {
      margin: 0 auto;
      background-color: transparent;
      color: white;
      border: 0;
      font-size: 18px;
      padding: 0 100px;

      :hover {
        cursor: pointer;
      }
    }
  }

  .form-group {
    border-bottom: 1px solid #eee;
  }

  .email {
    width: 90%;
  }
  
`;
import React from "react";
import { Helmet } from "react-helmet";

import { HistoryContainer } from "./styles";
import { TitleBanner } from "../home/styles";

import stn_rink_history from "../../assets/stn_rink_history.webp";
import hobey_baker from "../../assets/hobey_baker.webp";

const History = () => {
  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>History | New York St. Nicks</title>
      </Helmet>
      <TitleBanner>
        <div>
          <div className="year-est">History</div>
          <div className="team-name">Over 100 Years of Elite<br />Men's Ice Hockey</div>
        </div>
      </TitleBanner>
      <HistoryContainer>
        <p className="caption"><i>Excerpts from Metro Ice: A Century of Hockey in Greater New York, by Stan Fischler</i></p>
        <p className="content">Organized hockey was played in the Metropolitan Area a lot earlier than most people would imagine and two rinks emerged late in the 19th Century, one in Manhattan and the other in Brooklyn, that rivaled those in Canada. With Manhattan's population center located south of 34th Street and the area north of 96th Street still lightly populated, it was appropriate that America's pioneer rink be located on Lexington Avenue and 101st Street in what is now Harlem. It was opened on December 14, 1894.</p>
        <div className="img-full" >
          <img src={stn_rink_history} alt="St. Nicholas Skating Rink, NYC" />
          <div className="caption center">St. Nicholas Skating Rink, New York City</div>
        </div>
        <p className="content"> A little over a year later the St. Nicholas Rink opened two miles south and across town, just a block from Central Park at 66th Street and Columbus Avenue. With such notable members as Cornelius Vanderbilt, Mr. and Mrs. John Jacob Astor, and Mr. and Mrs. J. Pierpont Morgan gliding across the ice, the St. Nicholas Rink promised to be one of the greatest in the world... In addition to housing a highly-regarded skating club, St. Nick's organized its own hockey team, which began competing in 1896. Kenneth Gordon of West Orange, New Jersey organized the first St. Nicholas hockey club.</p>
        <div className="img-float-left">
          <a href="https://en.wikipedia.org/wiki/Hobey_Baker" target="_blank" rel="noopener noreferrer"><img src={hobey_baker} alt="Hobey Baker" /></a>
          <div className="caption center">Hobey Baker 1915-1916</div>
        </div>
        <p className="content">At the approach of the 20th century, there were definite indicators that the ice game had taken hold in the Metropolitan Area. The most tangible evidence was the formation of the American Amateur Hockey League, otherwise known as the AAHL. Embracing the New York Athletic Club, St. Nick's and Brooklyn's Crescent Athletic Club as well as the Skating Club of Brooklyn, the first American amateur hockey league began a 21-year run that was only interrupted by Uncle Sam's entry into World War I in 1917.The amateur game hogged what hockey headlines there were in the numerous New York journals at the start of the 20th Century.</p>
        <p className="content">The Crescent Athletic Club and New York Wanderers shared top billing with St. Nick's through the 1907-08 season, but the Crescents led the AAHL in titles with seven to one apiece for the others.</p>
      </HistoryContainer>
    </div>
  );
}

export default History;
import React from 'react';
import { DateTime } from 'luxon';
import { Helmet } from "react-helmet";

import { ScheduleContainer, ScheduleTitle, ScheduleTable } from './styles';
import columnDefs from './columnDefs.js';

const data = [
    {
        gameId: 202220231,
        season: "20222023",
        date: "Dec 3, 2022",
        time: "4:00pm ET",
        homeTeam: "New York St. Nicks",
        homeScore: "7",
        visitingTeam: "Wissahickon Skating Club",
        visitingScore: "4",
        location: "New York, NY",
        venue: "Sky Rink at Chelsea Piers",
        venueLink: "https://www.google.com/maps/place/Sky+Rink+at+Chelsea+Piers/@40.7474421,-74.0105772,17z/data=!3m2!4b1!5s0x89c259c7b114c617:0xbbdbf8d29a0da3dd!4m5!3m4!1s0x89c259a6534d068f:0xe2bb89d0209e13c4!8m2!3d40.7474381!4d-74.0083832",
    },
    {
        gameId: 202220232,
        season: "20222023",
        date: "Jan 21, 2023",
        time: "4:15pm ET",
        homeTeam: "Wissahickon Skating Club",
        homeScore: "2",
        visitingTeam: "New York St. Nicks",
        visitingScore: "6",
        location: "Philadelphia, PA",
        venue: "Wissahickon Skating Club",
        venueLink: "https://www.google.com/maps/place/Wissahickon+Skating+Club/@40.0606604,-75.212234,17z/data=!3m1!4b1!4m5!3m4!1s0x89c6b90b62e21b35:0xd7a5b07d572ce1f3!8m2!3d40.0606105!4d-75.21014",
    },
    {
        gameId: 202220233,
        season: "20222023",
        date: "Feb 17, 2023",
        time: "7:30pm MT",
        homeTeam: "Jackson Hole Moose",
        homeScore: "4",
        visitingTeam: "New York St. Nicks",
        visitingScore: "3",
        location: "Jackson, WY",
        venue: "Snow King Sports & Events Center",
        venueLink: "https://www.google.com/maps/place/Snow+King+Sports+%26+Events+Center/@43.4720412,-110.7766755,15z/data=!3m1!4b1!4m5!3m4!1s0x405a0305c2213929:0x3e2e636eb6c237a4!8m2!3d43.4720269!4d-110.7597881",
    },
    {
        gameId: 202220234,
        season: "20222023",
        date: "Feb 18, 2023",
        time: "7:30pm MT",
        homeTeam: "Jackson Hole Moose",
        homeScore: "4",
        visitingTeam: "New York St. Nicks",
        visitingScore: "6",
        location: "Jackson, WY",
        venue: "Snow King Sports & Events Center",
        venueLink: "https://www.google.com/maps/place/Snow+King+Sports+%26+Events+Center/@43.4720412,-110.7766755,15z/data=!3m1!4b1!4m5!3m4!1s0x405a0305c2213929:0x3e2e636eb6c237a4!8m2!3d43.4720269!4d-110.7597881",
    },
    {
        gameId: 202220235,
        season: "20222023",
        date: "Apr 22, 2023",
        time: "4:00pm ET",
        homeTeam: "New York St. Nicks",
        homeScore: "5",
        visitingTeam: "Castle Island Hockey Club",
        visitingScore: "2",
        location: "New York, NY",
        venue: "Sky Rink at Chelsea Piers",
        venueLink: "https://www.google.com/maps/place/Sky+Rink+at+Chelsea+Piers/@40.7474421,-74.0105772,17z/data=!3m2!4b1!5s0x89c259c7b114c617:0xbbdbf8d29a0da3dd!4m5!3m4!1s0x89c259a6534d068f:0xe2bb89d0209e13c4!8m2!3d40.7474381!4d-74.0083832",
    },
    {
        gameId: 202320242,
        season: "20232024",
        date: "Dec 9, 2023",
        time: "4:00pm ET",
        homeTeam: "New York St. Nicks",
        homeScore: "3",
        visitingTeam: "Wissahickon Skating Club",
        visitingScore: "5",
        location: "New York, NY",
        venue: "Sky Rink at Chelsea Piers",
        venueLink: "https://www.google.com/maps/place/Sky+Rink+at+Chelsea+Piers/@40.7474421,-74.0105772,17z/data=!3m2!4b1!5s0x89c259c7b114c617:0xbbdbf8d29a0da3dd!4m5!3m4!1s0x89c259a6534d068f:0xe2bb89d0209e13c4!8m2!3d40.7474381!4d-74.0083832",
    },
    {
        gameId: 202320243,
        season: "20232024",
        date: "Jan 12, 2024",
        time: "7:45pm MT",
        homeTeam: "Vail Yeti",
        homeScore: "6 (SO)",
        visitingTeam: "New York St. Nicks",
        visitingScore: "7",
        location: "Vail, CO",
        venue: "John A. Dobson Ice Arena",
        venueLink: "https://www.google.com/maps/place/John+A.+Dobson+Ice+Arena/@39.6438813,-106.3862408,17z/data=!3m1!4b1!4m6!3m5!1s0x876a7067a7fad5b9:0x6c0a68b1bcdd123e!8m2!3d39.6438772!4d-106.3840521!16s%2Fg%2F1whdjvjn",
    },
    {
        gameId: 202320244,
        season: "20232024",
        date: "Jan 13, 2024",
        time: "7:45pm MT",
        homeTeam: "Vail Yeti",
        homeScore: "3 (OT)",
        visitingTeam: "New York St. Nicks",
        visitingScore: "2",
        location: "Vail, CO",
        venue: "John A. Dobson Ice Arena",
        venueLink: "https://www.google.com/maps/place/John+A.+Dobson+Ice+Arena/@39.6438813,-106.3862408,17z/data=!3m1!4b1!4m6!3m5!1s0x876a7067a7fad5b9:0x6c0a68b1bcdd123e!8m2!3d39.6438772!4d-106.3840521!16s%2Fg%2F1whdjvjn",
    },
    {
        gameId: 202320245,
        season: "20232024",
        date: "Feb 16, 2024",
        time: "7:15pm MT",
        homeTeam: "Sun Valley Suns",
        homeScore: "6",
        visitingTeam: "New York St. Nicks",
        visitingScore: "2",
        location: "Sun Valley, ID",
        venue: "Campion Ice House",
        venueLink: "https://www.google.com/maps/place/Campion+Ice+House/@43.5133189,-114.3139843,16z/data=!4m6!3m5!1s0x54abd5ad3f60fc63:0x7b97ac520796b2d7!8m2!3d43.5133189!4d-114.3088345!16s%2Fg%2F11clhqmr_r?entry=ttu",
    },
    {
        gameId: 202320246,
        season: "20232024",
        date: "Feb 17, 2024",
        time: "7:15pm MT",
        homeTeam: "Sun Valley Suns",
        homeScore: "6",
        visitingTeam: "New York St. Nicks",
        visitingScore: "2",
        location: "Sun Valley, ID",
        venue: "Campion Ice House",
        venueLink: "https://www.google.com/maps/place/Campion+Ice+House/@43.5133189,-114.3139843,16z/data=!4m6!3m5!1s0x54abd5ad3f60fc63:0x7b97ac520796b2d7!8m2!3d43.5133189!4d-114.3088345!16s%2Fg%2F11clhqmr_r?entry=ttu",
    },
    {
        gameId: 202320247,
        season: "20232024",
        date: "Mar 29, 2024",
        time: "7:00pm ET",
        homeTeam: "FDNY",
        homeScore: "4",
        visitingTeam: "New York St. Nicks",
        visitingScore: "7",
        location: "Brooklyn, NY",
        venue: "Abe Stark Sports Center",
        venueLink: "https://www.google.com/maps/place/Abe+Stark+Sports+Center/@40.5730556,-73.9884082,17z/data=!3m1!4b1!4m6!3m5!1s0x89c245b5ae676a69:0xf755af67d8a8a9b6!8m2!3d40.5730556!4d-73.9858333!16s%2Fg%2F1thbxl1l?entry=ttu",
    },
    {
        gameId: 202320248,
        season: "20232024",
        date: "Apr 20, 2024",
        time: "4:00pm ET",
        homeTeam: "New York St. Nicks",
        homeScore: "8",
        visitingTeam: "Castle Island Hockey Club",
        visitingScore: "4",
        location: "New York, NY",
        venue: "Sky Rink at Chelsea Piers",
        venueLink: "https://www.google.com/maps/place/Sky+Rink+at+Chelsea+Piers/@40.7474421,-74.0105772,17z/data=!3m2!4b1!5s0x89c259c7b114c617:0xbbdbf8d29a0da3dd!4m5!3m4!1s0x89c259a6534d068f:0xe2bb89d0209e13c4!8m2!3d40.7474381!4d-74.0083832",
    },
];

const upcomingGames = data.filter((game) => (DateTime.fromFormat(game.date, "LLL d, yyyy") > Date.now()));
const recentGames = data.filter((game) => (DateTime.fromFormat(game.date, "LLL d, yyyy") < Date.now())).sort();

const Schedule = () => {
    return (
        <ScheduleContainer>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Schedule | New York St. Nicks</title>
            </Helmet>
            {upcomingGames.length > 0 && (
                <div>
                    <ScheduleTitle>St. Nicks Upcoming Schedule</ScheduleTitle>
                    <div className='table-cont'>
                        <ScheduleTable
                            columns={columnDefs.scheduleColumns}
                            data={upcomingGames}
                        />
                    </div>
                    < br />
                </div>
            )}
            {recentGames.length > 0 && (
                <div>
                    <ScheduleTitle>Recent Scores</ScheduleTitle>
                    <div className='table-cont'>
                        <ScheduleTable
                            columns={columnDefs.recentScoreColumns}
                            data={recentGames}
                            defaultSorted={[{ id: "date", desc: true }]}
                        />
                    </div>
                </div>
            )}

        </ScheduleContainer>
    );
}

export default Schedule;
import styled from "styled-components";
import StnTable from "../../core/StnTable/StnTable";

export const RosterContainer = styled.div`
  font-family: 'EB Garamond', serif;

  .roster-title {
    margin: 140px auto 20px 20px;
    font-size: 48px;
  }

  @media(max-width: 768px) {
    .roster-title {
      margin: 120px auto 20px 20px;
      font-size: 34px;
    }
  }

  @media(max-width: 425px) {
    .roster-title {
      margin: 100px auto 20px 10px;
      font-size: 34px;
    }
  }
`;

export const RosterTableContainer = styled.div`
  margin: 0 20px 20px;
  overflow: scroll;
  display: flex;
  
  @media(max-width: 425px) {
    margin: 0 1px 20px;
  }

  .td {
    padding: 2px 0;
  }
`;

export const SubComponentContainer = styled.div`
  padding-left: 40px;

  .td {
    padding: 0;
  }
`;

export const SubStnTable = styled(StnTable)`

`;